import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'

const Landing = (props) => (
    <Layout>
        <Helmet>
            <title>Affiliations &amp; Collaborations - Kimberly Plomp, PhD</title>
            <meta name="description" content="List of Affiliations and Collaborations" />
            <meta name="keywords" content="affiliations,collaborations,universities,departments,colleagues" />
        </Helmet>

        <BannerLanding title="Affiliations &amp; Collaborations" subtitle="Where I am and who I’m working with" />

        <div id="main">
            <section id="one" className="spotlights">
                <section>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Current Affiliations</h3>
                            </header>
                            <div className="table-wrapper">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td><a href="http://asp.upd.edu.ph/index.html" target="_blank" rel="noreferrer">Archaeological Studies Program, University of the Philippines</a></td>
                                        </tr>
                                        <tr>
                                            <td><a href="http://www.sfu.ca/archaeology.html" target="_blank" rel="noreferrer">Department of Archaeology, Simon Fraser University</a></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Collaborators</h3>
                            </header>
                            <div className="table-wrapper">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td><a href="https://www.sfu.ca/archaeology/faculty/collard.html" target="_blank" rel="noreferrer">Mark Collard, Department of Archaeology, Simon Fraser University</a></td>
                                        </tr>
                                        <tr>
                                            <td><a href="https://www.sydney.edu.au/arts/about/our-people/academic-staff/keith-dobney.html" target="_blank" rel="noreferrer">Keith Dobney, Department of Archaeology, University of Sydney</a></td>
                                        </tr>
                                        <tr>
                                            <td><a href="https://www.arkeologi.uu.se/staff/Presentations/neil-price/" target="_blank" rel="noreferrer">Neil Price, Department of Archaeology and Ancient History, Uppsala University</a></td>
                                        </tr>
                                        <tr>
                                            <td>Hildur Gestdóttir, Institute of Archaeology, Iceland</td>
                                        </tr>
                                        <tr>
                                            <td>Una Strand Vidarsdottir, University of Iceland</td>
                                        </tr>
                                        <tr>
                                            <td><a href="https://anth.ubc.ca/profile/darlene-weston/" target="_blank" rel="noreferrer">Darlene Weston, Department of Anthropology, University of British Columbia</a></td>
                                        </tr>
                                        <tr>
                                            <td><a href="https://www.dur.ac.uk/directory/profile/?id=163" target="_blank" rel="noreferrer">Charlotte Roberts, Department of Archaeology, Durham University</a></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </div>

    </Layout>
)

export default Landing
